<template>
  <div class="form-field">
    <p
      class="form-field__title"
      :class="{ 'form-field--error': !validation.success }"
    >
      <span v-if="specification.require">*</span> {{ specification.label }}
    </p>
    <Input
      v-if="specification.type === 'input'"
      v-model="value"
      type="text"
      :placeholder="specification.label"
      :is-error="!validation.success"
      :disabled="disabled"
      :autofocus="specification.autofocus"
      class="form-field__element"
      @blur.native="
        check();
        trimValue();
      "
    />
    <Select
      v-else-if="specification.type === 'select'"
      :default="selectViewDefault"
      :options="specification.options"
      show-model="name"
      :mode="['table']"
      @blur="check"
      @selectOption="setOption"
    />
    <Toggler
      v-else-if="specification.type === 'toggle'"
      :value="value"
      :isChecked="value"
      :onToggleHandler="toggleHandler"
      :togglerWidth="200"
      class="form-field__element form-field__element--toggle"
    >
      <template #firstLabel>{{ specification.options.true }}</template>
      <template #secondLabel>{{ specification.options.false }}</template>
    </Toggler>
    <flat-pickr
      v-else-if="specification.type === 'date'"
      v-model="value"
      :config="calendarConfig"
      placeholder="дд.мм.гггг"
      @change.native="check"
    ></flat-pickr>
    <p
      v-if="!validation.success && validation.message"
      class="form-field--error"
    >
      {{ validation.message }}
    </p>
  </div>
</template>

<script>
import { DATE_FORMAT_FOR_FLAT_PICKR } from "@/assets/constants.js";
import Input from "@/components/Input.vue";
import Select from "@/components/Select.vue";
import Toggler from "@/components/ui/Toggler.vue";

export default {
  name: "FormsField",
  components: {
    Input,
    Select,
    Toggler,
  },
  props: {
    default: {
      type: [String, Number, Boolean, Date],
      default: "",
    },
    specification: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      require: false,
    },
  },
  data() {
    return {
      value: "",
      isPrimeval: true,
      validation: {
        success: true,
        message: "",
      },
    };
  },
  created() {
    if (this.default !== null || this.default !== undefined) {
      this.value = this.default;
    }
  },
  watch: {
    value() {
      this.update();
    },
  },
  computed: {
    selectViewDefault() {
      return (
        this.specification.options.find((option) => option.id === this.default)
          ?.name || "Не выбрано"
      );
    },
    calendarConfig() {
      return {
        maxDate: new Date(),
        allowInput: true,
        dateFormat: DATE_FORMAT_FOR_FLAT_PICKR,
        locale: { firstDayOfWeek: 1 },
      };
    },
  },
  methods: {
    setOption(option) {
      this.value = option.id;
    },
    toggleHandler() {
      this.value = !this.value;
    },
    setDate(date) {
      this.value = date;
    },
    update() {
      this.$emit("update", { id: this.specification.name, value: this.value });
    },
    check() {
      this.isPrimeval = false;

      if (this.specification.require) {
        this.validation = this.specification.validator
          ? this.specification.validator(this.value)
          : {
              success: !!this.value,
              message: !this.value && "Отсутствует обязательное значение",
            };
      }
    },
    trimValue() {
      this.value ??= this.value?.trim();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.form-field {
  margin: 10px 0 0;
}

.form-field__title {
  font-size: 15px;
  margin-bottom: 4px;
}

.form-field--error {
  color: $erp-error-color;
}

.form-field__element {
  width: 100%;
  height: 26px;
  &--toggle {
    width: 200px;
  }
}
</style>
