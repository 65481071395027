<template>
  <div class="employees-info">
    <StickyHeader>
      <div
        class="employees-info__head columns-template"
        :class="{ 'columns-template--edit': hasEditingAccess }"
      >
        <div class="employees-info__head-column">ФИО</div>
        <div class="employees-info__head-column">Квалификация</div>
        <div class="employees-info__head-column">Позиция</div>
        <div class="employees-info__head-column">Корпоративная почта</div>
        <div class="employees-info__head-column">Телефон</div>
        <div class="employees-info__head-column">Telegram контакт</div>
        <div class="employees-info__head-column">ID</div>
        <div v-if="hasEditingAccess" class="employees-info__head-column"></div>
      </div>
    </StickyHeader>
    <div class="employees-info__body-container">
      <div
        v-for="employee of list"
        :key="employee.id"
        class="employees-info__body columns-template"
        :class="{ 'columns-template--edit': hasEditingAccess }"
      >
        <div class="employees-info__body-column">
          {{ fullName(employee) }}
        </div>
        <div class="employees-info__body-column">
          {{ employee.qualification | capitalize }}
        </div>
        <div class="employees-info__body-column">
          {{ employee.position.role }}
        </div>
        <div v-if="employee.email" class="employees-info__body-column">
          <CopyElement :value="employee.email" />
        </div>
        <div class="employees-info__body-column">
          <CopyElement :value="employee.phone" />
        </div>
        <div class="employees-info__body-column">
          <CopyElement :value="employee.telegram_contact" />
        </div>
        <div class="employees-info__body-column">{{ employee.id }}</div>
        <div v-if="hasEditingAccess" class="employees-info__body-column">
          <Button theme="icon" @click="edit(employee)">
            <Edit />
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CopyElement from "@/components/CopyElement.vue";
import StickyHeader from "@/components/StickyHeader.vue";
import Button from "@/components/Button.vue";
import Edit from "@/assets/img/edit.svg?inline";

export default {
  name: "employees-info-table",
  components: {
    CopyElement,
    StickyHeader,
    Button,
    Edit,
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    hasEditingAccess: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  destroyed() {
    this.clearSearch();
    this.clearQualFilter();
  },
  computed: {
    ...mapGetters({
      fullName: "staffStore/fullName",
    }),
  },
  methods: {
    ...mapActions({
      loadCurrentStaff: "staffStore/loadCurrentStaff",
    }),
    ...mapMutations({
      clearSearch: "staffStore/clearSearch",
      clearQualFilter: "staffStore/clearQualFilter",
    }),
    async edit(employee) {
      const employeeDetails = await this.loadCurrentStaff(employee.id);
      this.$emit("edit-employee", "edit", employeeDetails);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.columns-template {
  display: grid;
  grid-template-columns: 220px 140px 150px 250px 160px 170px 60px;
}

.columns-template--edit {
  grid-template-columns: 220px 140px 150px 250px 160px 170px 60px 60px;
}

.employees-info {
  width: min-content;
  background: linear-gradient($erp-primary-bg-color 40px, #ffffff 40px);
  padding: 0 3px;
  border-radius: 7px;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.employees-info__head {
  background-color: $erp-primary-bg-color;
  color: #ffffff;
  text-align: center;
  border-radius: 7px 7px 0 0;
  padding: 10px 0;
  font-weight: 700;
}

.employees-info__head-column:not(:first-child) {
  border-left: 1px solid #ffffff;
}

.employees-info__body-container {
  height: auto;
  scrollbar-width: thin;
}

.employees-info__body-container::-webkit-scrollbar {
  width: 2px;
}

.employees-info__body-container::-webkit-scrollbar-thumb {
  background-color: #c1d2dd;
  border-radius: 10em;
}

.employees-info__body {
  padding: 10px 0;
}

.employees-info__body:not(:last-child) {
  border-bottom: 1px solid #aaaaaa;
}

.employees-info__body-column {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.employees-info__body-column:first-child {
  padding-left: 5px;
}

.employees-info__body-column:not(:first-child) {
  justify-content: center;
  text-align: center;
  padding: 0 4px;
  border-left: 1px solid #aaaaaa;
}

.employees-info__body-column-link {
  color: inherit;
}
</style>
