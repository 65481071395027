<template>
  <input
    ref="inputRef"
    :class="{
      ...classNames,
      'input--focused': isFocused,
      'input--saving-in-progress': isDataSavingInProgress,
      'input--saved-complete': isDataSavedComplete,
      'input--saved-error': isDataSavedError,
      'input--error': isError,
    }"
    @change="dataInputChanged"
    @input="changeDataInput"
    v-model="dataInput"
    type="text"
    @focus="onFocus"
    @blur="onBlur"
    @keyup.enter="enterEnterKey"
  />
</template>

<script>
import {
  convertHoursToMinutes,
  convertMinutesToHours,
  isObjectEmpty,
} from "../assets/utils";

export default {
  name: "Input",
  data() {
    return {
      prevDataInput: "",
      dataInput: "",
      beforeBlurDataInput: "",
      isFocused: false,
      isDataSavingInProgress: false,
      isDataSavedComplete: false,
      isDataSavedError: false,
    };
  },
  model: {
    prop: "startValue",
    event: "change",
  },
  props: {
    classNames: {
      type: Object,
      default() {
        return {};
      },
    },
    isEditable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isOnlyNumbers: {
      type: Boolean,
      default() {
        return false;
      },
    },
    minNumber: {
      type: Number,
      default() {
        return 0;
      },
    },
    maxNumber: {
      type: Number,
      default() {
        return 24;
      },
    },
    maxMinutesNumber: {
      type: Number,
      default() {
        return 1440;
      },
    },
    startValue: {
      type: [String, Number],
    },
    saveData: {
      type: Function,
      // default() {
      //   return () => null;
      // }
    },
    isError: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    context: {
      require: false,
    },
    timeFormat: {
      type: String,
      default() {
        return "hours";
      },
    },
  },
  created() {
    this.dataInput = this.$props.startValue;
    this.beforeBlurDataInput = this.$props.startValue;
    this.prevDataInput = this.$props.startValue;
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs.inputRef.focus();
      });
    }
  },
  methods: {
    changeDataInput(e) {
      if (this.isOnlyNumbers) {
        if (e.inputType === "insertText") {
          this.dataInput = this.dataInput.replace(",", ".");
          if (this.isHoursValid || this.isMinutesValid) {
            if (e.data === ".") {
              this.dataInput += ``;
            }
          } else {
            this.dataInput = this.prevDataInput;
            this.$emit("number-out-range", {
              min: this.minNumber,
              max: this.maxNumber,
              maxMinutes: this.maxMinutesNumber,
            });
          }
        } else if (e.inputType === "deleteContentBackward") {
          // this.dataInput = this.dataInput.slice(0, -1);
        }
      }

      this.prevDataInput = this.dataInput;
    },
    dataInputChanged() {
      this.$emit("change", this.dataInput, this.context);
      if (this.dataInput[this.dataInput.length - 1] === ".") {
        this.dataInput = this.dataInput.slice(0, -1);
      }
    },
    onFocus(e) {
      this.isFocused = true;
      if (!e.currentTarget.value) {
        return;
      }
      if (this.timeFormat === "hours") {
        this.beforeBlurDataInput = e.currentTarget.value || "";
      }
      if (this.timeFormat === "minutes") {
        this.dataInput = convertHoursToMinutes(+e.currentTarget.value) || "";
        this.beforeBlurDataInput = convertMinutesToHours(+this.dataInput) || "";
      }
    },
    async onBlur() {
      this.isFocused = false;
      if (this.timeFormat === "minutes") {
        this.dataInput = convertMinutesToHours(+this.dataInput) || "";
      }
      if (!this.saveData || this.dataInput === this.beforeBlurDataInput) {
        return;
      }
      this.isDataSavingInProgress = true;
      try {
        const res = await this.saveData(
          parseFloat(this.dataInput),
          this.context
        );
        if (
          (!parseFloat(this.dataInput) && isObjectEmpty(res) && res !== null) ||
          res?.actual_hours ||
          res?.success
        ) {
          this.isDataSavedComplete = true;
          if (this.dataInput === "" || this.dataInput === "0") {
            this.dataInput = "";
            this.beforeBlurDataInput = "";
          }
        } else {
          this.isDataSavedError = true;
          this.dataInput = this.beforeBlurDataInput;
        }
      } catch (e) {
        console.log(e);
        this.dataInput = this.beforeBlurDataInput;
        this.isDataSavedError = true;
      }
      this.isDataSavingInProgress = false;
      setTimeout(() => {
        this.isDataSavedError = false;
        this.isDataSavedComplete = false;
      }, 1500);
    },
    async enterEnterKey() {
      this.$refs.inputRef.blur();
    },
  },
  computed: {
    isMinutesValid() {
      return (
        /^\d{1,4}$/.test(this.dataInput) &&
        this.timeFormat === "minutes" &&
        parseInt(this.dataInput) >= this.minNumber &&
        parseInt(this.dataInput) <= this.maxMinutesNumber
      );
    },
    isHoursValid() {
      return (
        /^[\d]*[.]?[\d{1}]?$/.test(this.dataInput) &&
        this.timeFormat === "hours" &&
        parseFloat(this.dataInput) >= this.minNumber &&
        parseFloat(this.dataInput) <= this.maxNumber
      );
    },
  },
  watch: {
    startValue(newData) {
      this.dataInput = newData;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.input--focused {
  background-color: #cad0d4 !important;
}

.input--saving-in-progress {
  background-color: #f5de70 !important;
}

.input--saved-complete {
  background-color: #82d688 !important;
}

.input--saved-error {
  background-color: $erp-error-color !important;
}

.input--error {
  border: 1px solid $erp-error-color;
}
</style>
