<template>
  <div class="toggler">
    <div class="toggler__title">{{ title }}</div>

    <label class="toggler__toggler" :style="{ width: togglerWidth + 'px' }">
      <input
        class="toggler__input"
        type="checkbox"
        :value="value"
        :checked="isChecked"
        @change="onToggleHandler($event)"
      />
      <span class="toggler__format toggler__format--min">
        <slot name="firstLabel"></slot>
      </span>

      <span class="toggler__format toggler__format--hours">
        <slot name="secondLabel"></slot>
      </span>

      <span class="toggler__switcher" />
    </label>
  </div>
</template>

<script>
export default {
  name: "Toggler",
  props: {
    onToggleHandler: {
      type: Function,
      default() {
        return () => {};
      },
    },
    title: {
      type: String,
      default() {
        return "";
      },
    },
    value: {
      type: [String, Boolean],
      default() {
        return true;
      },
    },
    isChecked: {
      type: Boolean,
      default() {
        return true;
      },
    },
    togglerWidth: {
      type: Number,
      default() {
        return 170;
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.toggler {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex: 1;
  width: 360px;
  justify-content: space-between;
}
.toggler__title {
  margin-right: 10px;
}
.toggler__toggler {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  background-color: #f3f3f3;
  border: 1px solid gray;
  width: 90px;
  height: 25px;
  border-radius: 25px;
  cursor: pointer;
}
.toggler__switcher {
  position: absolute;
  height: 100%;
  border-radius: 25px;
  background-color: $erp-primary-bg-color;
  z-index: 0;
}
.toggler__format {
  flex-basis: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  line-height: 15px;
  z-index: 1;
  color: $erp-dark-color;
  transition: color 0.3s;
}
.toggler__input {
  appearance: none;
  position: absolute;
  outline: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.25s -0.1s;
  &:checked {
    background-color: #fff;
    & ~ .toggler__switcher {
      left: 0;
      right: 50%;
      transition: left 0.4s, right 0.3s 0.2s;
    }
    & ~ .toggler__format--min {
      color: $erp-light-color;
      transition: color 0.4s;
    }
  }
  &:not(:checked) {
    background: $erp-primary-bg-color;
    transition: background 0.4s -0.1s;
    & ~ .toggler__switcher {
      left: 50%;
      right: 0;
      transition: left 0.3s 0.2s, right 0.4s;
    }
    & ~ .toggler__format--hours {
      color: $erp-light-color;
      transition: color 0.4s;
    }
  }
}
</style>
