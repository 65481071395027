<template>
  <div class="erp-copy">
    <span v-if="!isError" class="erp-copy__display">{{ value }}</span>
    <span v-else class="erp-copy__display">Ошибка копирования</span>
    <Button
      theme="icon"
      @click="copyText"
      :class="{
        'erp-copy__button': true,
        'erp-copy__button--saved': isSaved,
        'erp-copy__button--error': isError,
      }"
    >
      <TooltipCommon :delay="150" :distance="6" position="top">
        <template v-slot:message>
          <p class="erp-copy__tooltip-message">скопировать</p>
        </template>
        <CopyIcon />
        <span class="visually-hidden">Copy to Clipboard</span>
      </TooltipCommon>
    </Button>
  </div>
</template>

<script>
import TooltipCommon from "@/components/TooltipCommon.vue";
import CopyIcon from "@/assets/img/copy-icon.svg?inline";
import Button from "@/components/Button";

export default {
  name: "erp-copy",
  components: {
    TooltipCommon,
    CopyIcon,
    Button,
  },
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      isSaved: false,
      isError: false,
    };
  },
  methods: {
    copyText() {
      if (!navigator.clipboard) {
        console.log("Копирование не удалось. Обратитесь к разработчикам.");
        return;
      }
      navigator.clipboard.writeText(this.value).then(
        () => {
          this.isSaved = true;
          setTimeout(() => (this.isSaved = false), 2000);
        },
        (err) => {
          this.isError = true;

          setTimeout(() => (this.isError = false), 2000);
          console.log(err);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";
@import "@/assets/css/_z-index.scss";

.erp-copy {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-all;
}

.erp-copy:hover .erp-copy__button {
  opacity: 1;
  @include z-index(copy);
  transition: 350ms opacity;
}

.erp-copy__button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  opacity: 0;
  cursor: pointer;
  width: 25px;
  height: 25px;
  padding: 0;
  background-color: #b9b9b9;
  // border: 1px solid #525252;
  border-radius: 4px;

  & svg {
    width: 22px;
    height: 22px;
    fill: #525252;
  }
}

.erp-copy__button--saved {
  background-color: #dfffd1;
  transition: 500ms;
}

.erp-copy__button--error {
  background-color: #eb5b42;
  transition: 500ms;
}

.erp-copy__tooltip-message {
  width: max-content;
  border-radius: 6px;
  border: 1px solid #b9b9b9;
  background: #ffffff;
  color: #6e6e6e;
  font-weight: 400;
  padding: 4px;
}
</style>
