<template>
  <div
    ref="tooltipRef"
    class="tooltip"
    @mouseover="showMessage"
    @mouseleave="hideMessage"
  >
    <div
      v-show="isShowMessage && showTooltip"
      ref="messageRef"
      :style="messageStyle"
      class="tooltip__message"
    >
      <slot name="message" />
    </div>
    <slot />
  </div>
</template>

<script>
const POSITIONS_OPPOSIT = {
  top: "bottom",
  right: "left",
  bottom: "top",
  left: "right",
};

export default {
  name: "TooltipCommon",
  props: {
    delay: {
      type: Number,
      validator: (value) => value >= 0,
      default: 1000,
    },
    position: {
      type: String,
      validator: (value) => ["top", "left", "bottom", "right"].includes(value),
      default: "top",
    },
    // отступ в пикселях от края блока-контейнера до сообщения-подсказки
    distance: {
      type: Number,
      default: 20,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShowMessage: false,
      lastEventTime: null,
      messageStyle: {},
      currentPosition: null,
    };
  },
  created() {
    this.currentPosition = this.position;
  },
  methods: {
    calcStyle() {
      const styles = {};

      this.$nextTick(() => {
        const position = this.currentPosition;
        const tooltipRect = this.$refs.tooltipRef.getBoundingClientRect();
        const messageRect = this.$refs.messageRef.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        styles[POSITIONS_OPPOSIT[position]] = `calc(100% + ${this.distance}px)`;

        if (position === "top" || position === "bottom") {
          styles.left = tooltipRect.width / 2 - messageRect.width / 2 + "px";
        } else {
          styles.top = tooltipRect.height / 2 - messageRect.height / 2 + "px";
        }

        if (tooltipRect.top - messageRect.height - this.distance < 0) {
          delete styles.bottom;
          styles.top = `calc(100% + ${this.distance}px)`;
        }
        if (
          tooltipRect.bottom + messageRect.height + this.distance >
          windowHeight
        ) {
          delete styles.top;
          styles.bottom = `calc(100% + ${this.distance}px)`;
        }
        if (tooltipRect.left - messageRect.width + tooltipRect.width / 2 < 0) {
          styles.left = 0 + "px";
        }
        if (tooltipRect.right + messageRect.width > windowWidth) {
          styles.left = windowWidth - tooltipRect.right - 20 + "px";
        }

        this.messageStyle = styles;
      });
    },
    showMessage() {
      if (this.showTooltip) {
        const now = Date.now();
        this.lastEventTime = now;
        setTimeout(() => {
          if (now < this.lastEventTime) {
            return;
          }
          this.isShowMessage = true;
          this.calcStyle();
        }, this.delay);
      }
    },
    hideMessage() {
      this.lastEventTime = Date.now();
      this.isShowMessage = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  position: relative;
}

.tooltip__message {
  position: absolute;
  z-index: 10;
  width: max-content;
  max-width: 100%;
}
</style>
