var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeModal),expression:"closeModal"}],staticClass:"custom-select",class:{
    'custom-select--disabled': _vm.disabled,
    'custom-select--default': _vm.mode.includes('default'),
    'custom-select--table': _vm.mode.includes('table'),
    'custom-select--rounded': _vm.mode.includes('rounded'),
  }},[_c('div',{staticClass:"selected",class:{
      open: _vm.isOpen,
      'selected--no-ready': !_vm.isReady,
      'selected--no-wrap': _vm.onlyOneLine,
    },on:{"click":_vm.modalToggle,"contextmenu":_vm.openContextMenu}},[_c('span',{staticClass:"selected__text"},[_vm._v(_vm._s(_vm.selected))])]),(_vm.options.length || _vm.isOpen)?_c('div',{staticClass:"custom-select__modal",class:{ 'select-hide': !_vm.isOpen },on:{"keydown":_vm.optionsNavigation}},[(_vm.mode.includes('with-search'))?_c('div',{staticClass:"custom-select__search-wrapper"},[_c('CommonSearch',{ref:"search",attrs:{"placeholder":_vm.searchPlaceholder,"autofocus":true,"isOpen":_vm.isOpen},on:{"inputValue":_vm.searchValue}})],1):_vm._e(),_c('div',{staticClass:"modal__list modal-scroll"},[_c('ul',{staticClass:"modal__list-container"},_vm._l((_vm.options),function(option){return _c('li',{key:option.id,ref:"options",refInFor:true,staticClass:"modal__list-item",attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.selectOption(option)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.selectOption(option)}}},[_vm._v(" "+_vm._s(_vm.showValue(option))+" ")])}),0)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }