<template>
  <div
    class="custom-select"
    :class="{
      'custom-select--disabled': disabled,
      'custom-select--default': mode.includes('default'),
      'custom-select--table': mode.includes('table'),
      'custom-select--rounded': mode.includes('rounded'),
    }"
    v-click-outside="closeModal"
  >
    <div
      class="selected"
      :class="{
        open: isOpen,
        'selected--no-ready': !isReady,
        'selected--no-wrap': onlyOneLine,
      }"
      @click="modalToggle"
      @contextmenu="openContextMenu"
    >
      <span class="selected__text">{{ selected }}</span>
    </div>
    <div
      v-if="options.length || isOpen"
      class="custom-select__modal"
      :class="{ 'select-hide': !isOpen }"
      @keydown="optionsNavigation"
    >
      <div
        class="custom-select__search-wrapper"
        v-if="mode.includes('with-search')"
      >
        <CommonSearch
          ref="search"
          :placeholder="searchPlaceholder"
          :autofocus="true"
          :isOpen="isOpen"
          @inputValue="searchValue"
        />
      </div>
      <div class="modal__list modal-scroll">
        <ul class="modal__list-container">
          <li
            v-for="option of options"
            :key="option.id"
            ref="options"
            tabindex="0"
            class="modal__list-item"
            @click="selectOption(option)"
            @keydown.enter="selectOption(option)"
          >
            {{ showValue(option) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CommonSearch from "@/components/CommonSearch.vue";

export default {
  components: {
    CommonSearch,
  },
  model: {
    prop: "default",
    event: "selectOption",
  },
  props: {
    id: { type: [String, Number] },
    mode: {
      required: true,
      type: Array,
      validator: (prop) => prop.includes("default") || prop.includes("table"),
      default: function () {
        // Данный пропс обязательный.
        // Принимает массив, который может содержать одно из значений ["default", "table", "with-search", "rounded"]
        // при этом обязательно должно быть передано "default" или "table"
        return ["default"];
      },
    },
    searchPlaceholder: {
      type: String,
      default: "Поиск по имени",
    },
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    default: {
      type: [String, Number],
      required: false,
      default: "Не выбрано",
    },
    // если через "options" передается массив объектов, указываем ключ объекта,
    // значение которого будет отображаться или функцию,
    // которая вернет строковое значение для отображения
    showModel: {
      type: [String, Function],
    },
    context: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    isReady: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onlyOneLine: {
      type: Boolean,
      default: false,
    },
    customWidth: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      selected: this.default,
      isOpen: false,
      activeOptionIndex: null,
    };
  },
  watch: {
    default(value) {
      this.selected = value;
    },
    options() {
      this.reset();
    },
    selected() {
      this.$emit("blur");
    },
  },
  methods: {
    closeModal() {
      setTimeout(() => {
        if (this.isInputFocus) {
          return;
        }
        this.isOpen = false;
      }, 150);
    },
    modalToggle() {
      if (!this.isReady) {
        this.isOpen = false;
        return;
      }
      this.isOpen = !this.isOpen;
    },
    showValue(value) {
      if (typeof this.showModel === "function") {
        return this.showModel(value, this.context);
      } else {
        return value[this.showModel] || value;
      }
    },
    selectOption(option) {
      this.isOpen = false;
      this.selected = this.showValue(option);
      this.$emit("selectOption", option, this.context);
      this.$emit("updated", {
        changed: {
          elemName: this.id || "[Select]: ID не задан",
          elemType: "select",
          value: option,
        },
      });
    },
    searchValue(inputValue) {
      this.$emit("searchValue", inputValue);
      this.activeOptionIndex = null;
    },
    optionsNavigation(event) {
      const key = event.key;
      let index = this.activeOptionIndex;
      const options = this.$refs.options;
      if (key !== "ArrowDown" && key !== "ArrowUp") {
        return;
      }
      if (key === "ArrowDown") {
        switch (index) {
          case null:
            index = 0;
            break;
          case options.length - 1:
            index = 0;
            break;
          default:
            index = index + 1;
            break;
        }
        setTimeout(() => {
          options[index]?.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }, 0);
      } else if (key === "ArrowUp") {
        switch (index) {
          case null:
            index = options.length - 1;
            break;
          case 0:
            index = null;
            this.$refs.search.setFocus();
            break;
          default:
            index = index - 1;
            break;
        }
        setTimeout(() => {
          options[index]?.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }, 0);
      }
      this.activeOptionIndex = index;
      if (index === null) {
        return;
      }
      options[index].focus();
    },
    reset() {
      this.selected = this.default;
    },
    openContextMenu(event) {
      this.$emit("contextmenu", event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables";

.custom-select {
  display: flex;
  width: v-bind(customWidth);
  position: relative;
  text-align: left;
  outline: none;
  height: auto;
  cursor: pointer;
}

.custom-select--default {
  font-weight: 700;
  border: 1px solid #cad0d4;
  background-color: #ffffff;
  border-radius: 2px;
}

.custom-select--table {
  background-color: $erp-secondary-bg-color;
}

.custom-select--rounded {
  border: 1.5px solid $erp-dark-color;
  padding: 5px;
  border-radius: 8px;
}

.custom-select--disabled {
  pointer-events: none;
  color: $erp-disabled-color;
}

.custom-select .selected {
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  user-select: none;
}

.custom-select .selected.open {
  border-radius: 6px 6px 0px 0px;
}

.selected::after {
  position: absolute;
  content: "";
  top: 50%;
  right: 6px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-color: #808080 transparent transparent transparent;
  opacity: 0.5;

  .custom-select--disabled & {
    display: none;
  }
}

.custom-select .selected--no-ready::after {
  opacity: 0;
}

.selected--no-wrap .selected__text {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-select__modal {
  padding-top: 12px;
  position: absolute;
  background-color: inherit;
  left: 0;
  right: 0;
  top: calc(100% + 1px);
  z-index: 11;
  box-shadow: 4px 8px 8px -4px rgba(34, 60, 80, 0.2);
  font-weight: 500;
}

.modal__list {
  max-height: 340px;
  overflow: auto;
  padding-top: 4px;
}

.modal__list-container {
  height: 100%;
  list-style-type: none;
  padding-left: 0;
}

.modal__list-item {
  padding: 4px 10px;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: #247096;
  }

  &:focus {
    color: #ffffff;
    background-color: #247096;
  }
}

.select-hide {
  display: none;
}

.custom-select__search-wrapper {
  padding: 0 7px;
}
</style>
