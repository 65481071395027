<template>
  <div class="container">
    <h2>Сотрудники</h2>
    <div class="filter-block">
      <InputFinder
        placeholder="Поиск по имени"
        :isInstant="true"
        :autofocus="true"
        @changeValue="setName"
      />
      <div class="filter-block__checkbox">
        <CheckboxFilterModal
          title="Квалификация"
          :list="qualsList"
          :all-set-at-initialization="true"
          @setValue="setQual"
        />
        <CheckboxFilterModal
          title="Позиция"
          :list="positionList"
          :all-set-at-initialization="true"
          @setValue="setPosition"
        />
        <Button
          v-if="hasEditingAccess"
          theme="common"
          @click="openModal('create')"
          >Создать
        </Button>
      </div>
    </div>
    <EmployeesInfoTable
      :list="staffList"
      :has-editing-access="hasEditingAccess"
      @edit-employee="openModal"
    />
    <Pagination :data="staffData" />
    <EmployeeModal ref="employee-modal" @put-data="putData" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { QUALIFICATIONS_LIST } from "@/assets/constants";
import { ROLES } from "@/assets/enums";

import EmployeesInfoTable from "@/components/EmployeesInfoTable";
import Pagination from "@/components/Pagination";
import CheckboxFilterModal from "@/components/CheckboxFilterModal";
import InputFinder from "@/components/InputFinder";
import EmployeeModal from "@/components/modal/EmployeeModal";
import Button from "@/components/Button";

import loadPageMixin from "@/mixins/loadPageMixin";

export default {
  name: "employees-page",
  components: {
    EmployeesInfoTable,
    CheckboxFilterModal,
    InputFinder,
    Pagination,
    EmployeeModal,
    Button,
  },
  mixins: [loadPageMixin],
  async created() {
    await this.loadActivitiesList();
    await this.setSearch("");
  },
  destroyed() {
    this.clearPositionFilter();
  },
  computed: {
    ...mapState({
      staffList: (state) => state.staffStore.staffList,
      staffData: (state) => state.staffStore.staffData,
      activitiesList: (state) => state.planningsStore.activitiesList,
    }),
    ...mapGetters({
      isInGroup: "userStore/isInGroup",
    }),
    qualsList() {
      return QUALIFICATIONS_LIST.map((qual) => ({ name: qual, id: qual }));
    },
    positionList() {
      return this.activitiesList.map((item) => ({
        name: item.activity,
        id: item.id,
      }));
    },
    hasEditingAccess() {
      return this.isInGroup([ROLES.FOUNDER, ROLES.HR]);
    },
  },
  methods: {
    ...mapActions({
      loadStaffList: "staffStore/loadStaffList",
      setPageNumber: "staffStore/setPageNumber",
      setSearch: "staffStore/setSearch",
      setPositionFilter: "staffStore/setPositionFilter",
      setQualFilter: "staffStore/setQualFilter",
      createEmployee: "staffStore/createEmployee",
      updateEmployee: "staffStore/updateEmployee",
      loadActivitiesList: "planningsStore/loadActivitiesList",
    }),
    ...mapMutations({
      clearPositionFilter: "staffStore/clearPositionFilter",
    }),
    openModal(mode, data) {
      this.$refs["employee-modal"].open({ mode, data });
    },
    async setName(name) {
      if (this.$route.query.page !== "1") {
        this.$router.replace({ query: { page: 1 } });
      }
      await this.setSearch(name);
    },
    async setQual(qualList) {
      if (this.$route.query.page !== "1") {
        this.$router.replace({ query: { page: 1 } });
      }
      await this.setQualFilter(qualList);
    },
    async setPosition(positionIds) {
      if (this.$route.query.page !== "1") {
        this.$router.replace({ query: { page: 1 } });
      }
      await this.setPositionFilter(positionIds);
    },
    async putData({ mode, fields, id }) {
      if (mode === "create") {
        await this.createEmployee(fields);
      } else {
        await this.updateEmployee({ id, fields });
      }
      await this.loadStaffList();
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-block__checkbox {
  display: flex;
  column-gap: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
}
</style>
